import React from "react"
import { Form, Select } from "antd"

import { PROJECT_FILTER_OPTIONS } from "../utils/nav"

const ProjectFilter = ({
  selectedValue,
  changeHandler,
  clearFilterHandler,
}) => {
  const { Option } = Select

  return (
    <Form.Item label="Filter">
      <Select
        onChange={changeHandler}
        onClear={clearFilterHandler}
        value={selectedValue}
        style={{ width: 200 }}
        allowClear
      >
        {PROJECT_FILTER_OPTIONS.map(item => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default ProjectFilter
