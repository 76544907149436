import React, { Fragment, useContext, useCallback } from "react"
import { uniqueId } from "lodash"
import { graphql, Link } from "gatsby"
import { Col, Image, PageHeader, Row, Typography } from "antd"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/global-context-provider"
import SiteLayout from "../components/site-layout"
import ProjectFilter from "../components/project-filter"
import Seo from "../components/seo"
import { getProjects, getThumbnailPath } from "../utils/selectors"

import "../styles/index.less"

const IndexPage = ({ location, data }) => {
  /**
   * PROPS
   */
  const { Title } = Typography

  /**
   * STATE
   */
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const { selectedFilter } = state || {}

  /**
   * METHODS
   */
  const filterChangeHandler = useCallback(
    val => {
      dispatch({ type: "SET_FILTER", value: val })
    },
    [dispatch]
  )

  const clearFilterHandler = useCallback(() => {
    dispatch({ type: "SET_FILTER", value: null })
  }, [dispatch])

  /**
   * VARS
   */
  const projects = getProjects(data)

  const filteredProjects = selectedFilter
    ? projects.filter(project => project?.tags.includes(selectedFilter))
    : projects

  /**
   * RENDER
   */
  return (
    <SiteLayout location={location}>
      <Seo title="Portfolio" />
      <PageHeader
        className="content-page-header"
        title="Portfolio"
        extra={[
          <ProjectFilter
            selectedValue={selectedFilter}
            changeHandler={filterChangeHandler}
            clearHandler={clearFilterHandler}
          />,
        ]}
      >
        <Row gutter={[8, 48]} justify="space-evenly" align="middle">
          {filteredProjects.reverse().map(p => {
            const thumbnailSrc = getThumbnailPath(p.featuredImage)

            return (
              <Fragment key={p.id}>
                <Col
                  xl={8}
                  lg={8}
                  md={24}
                  sm={24}
                  xs={24}
                  className="portfolio-item"
                >
                  <Link to={`/portfolio/${p.slug}`}>
                    <div className="thumbnail">
                      <Image width={300} src={thumbnailSrc} preview={false} />
                      <div className="thumbnail__overlay">
                        <Title level={5} className="thumbnail-title">
                          {p.title}
                        </Title>
                      </div>
                    </div>
                  </Link>
                </Col>
              </Fragment>
            )
          })}

          {Array.apply(0, Array(3 - (filteredProjects.length % 3))).map(i => (
            <Fragment key={uniqueId}>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                className="portfolio-item"
              />
            </Fragment>
          ))}
        </Row>
      </PageHeader>
    </SiteLayout>
  )
}

/**
 * GRAPHQL
 */
export const query = graphql`
  query {
    allWpProject {
      edges {
        node {
          excerpt
          slug
          id
          featuredImage {
            node {
              id
              mediaDetails {
                sizes {
                  sourceUrl
                }
              }
            }
          }
          terms {
            nodes {
              slug
            }
          }
          title
        }
      }
    }
  }
`

export default IndexPage
